<template>
  <div class="md-layout user">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="user">
        <md-card-header
          data-background-color="green"
          class="card-header card-header-avatar"
        >
          <div class="user-header">
            <div class="user-avatar">
              <img
                :src="user.avatar || require('@/assets/img/new_logo.png')"
                alt="Аватар"
              />
            </div>
            <div class="user-info-header">
              <div class="user-info-name">User ID: {{ user.id }}</div>
              <div class="user-info-date">
                Дата регистрации: {{ getDate(user.created_at) }}
              </div>
              <div class="user-info-orders">
                Кол-во заказов: {{ user.orders_count }}
              </div>
              <div class="user-info-sum">
                Оборот: {{ user.orders_sum_price }}
              </div>
            </div>
          </div>
          <div class="user-header-buttons">
            <md-button class="md-raised md-primary" @click="changePassword">
              Сбросить пароль
            </md-button>
            <md-button class="md-raised md-danger" @click="showModal = true">
              Удалить пользователя
            </md-button>
          </div>
        </md-card-header>
        <md-card-content class="user-info md-layout">
          <md-field>
            <label>ФИО</label>
            <md-input v-model="user.name" :disabled="true" />
          </md-field>

          <md-field>
            <label>Телефон</label>
            <md-input
              v-model="user.phone"
              v-mask="'+# (###) ###-##-##'"
              md-counter="false"
              maxlength="18"
              :disabled="true"
            />
          </md-field>

          <md-field>
            <label>E-mail</label>
            <md-input v-model="user.email" :disabled="true" />
          </md-field>

          <div class="md-layout-item md-large-size-10">
            <md-field>
              <label>Статус</label>
              <md-input :value="getStatus(user.status)" :disabled="true" />
            </md-field>
          </div>

          <div
            class="md-layout-item md-large-size-100"
            style="text-align: right"
          >
            <md-button
              class="md-raised md-primary"
              @click="$router.push(`/users/edit/${user.id}`)"
            >
              Редактировать данные
            </md-button>
          </div>
        </md-card-content>
      </md-card>

      <UserOrdersTable
        :orders="orders"
        @prev="goToPage('prev')"
        @next="goToPage('next')"
      />
    </div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить пользователя?"
      @confirm="deleteUser"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";
import UserOrdersTable from "@/components/Tables/UserOrdersTable";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalConfirm,
    UserOrdersTable,
  },

  data() {
    return {
      currentPage: 1,
      showModal: false,
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.users.orders,
      user: (state) => state.users.user,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getUser({ user_id: +this.$route.params.id });
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  beforeDestroy() {
    this.getUser(null);
  },

  methods: {
    ...mapActions("users", ["getUser", "removeUser", "resetPassword"]),

    async changePassword() {
      this.$store.commit("SET_SHOW_LOADER", true);
      await this.resetPassword(this.user.id);
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    async deleteUser() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeUser(this.user.id);
      if (success) {
        this.showModal = false;
        this.$router.replace("/users/list");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    getStatus(status) {
      switch (status) {
        case 1:
        case "active":
          return "Активен";
        case 2:
        case "not_verified":
          return "Не подтвержден";
        default:
          return "";
      }
    },

    async goToPage(direction) {
      direction == "prev" ? this.currentPage-- : this.currentPage++;

      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getUser({
        user_id: +this.$route.params.id,
        page: this.currentPage,
      });
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-avatar {
  display: flex;
  justify-content: space-between;
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;

  .md-field {
    flex: 0 0 20%;
    margin: 10px 15px;
  }
}

.user-header {
  display: flex;

  &-buttons {
    display: flex;
    align-items: center;
  }

  .user-avatar {
    width: 80px;
    height: 80px;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-info-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>