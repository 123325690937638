<template>
  <div>
    <div
      v-if="orders && orders.data && orders.data.length"
      class="position-table"
    >
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <md-table v-model="orders.data">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          @click="openModal(item.id)"
        >
          <md-table-cell md-label="Номер заказа" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Кол-во товаров" class="custom-field">
            <span>{{ getQuantity(item.order_product) }}</span>
          </md-table-cell>

          <md-table-cell md-label="Доставка" class="custom-field">
            <span>{{ item.delivery_type }}</span>
          </md-table-cell>

          <md-table-cell md-label="Сумма" class="custom-field">
            <span>{{ getPrice(item.order_product) }}</span>
          </md-table-cell>

          <md-table-cell md-label="Дата заказа" class="custom-field">
            <span>{{ getDate(item.created_at) }}</span>
          </md-table-cell>

          <md-table-cell md-label="Дата оплаты" class="custom-field">
            <span>{{ getDate(item.payment_time) }}</span>
          </md-table-cell>

          <md-table-cell md-label="Статус" class="custom-field">
            <span>{{ item.status }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="orders.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="orders.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ orders.current_page }}</span>
          /
          <span>{{ orders.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="orders.current_page === orders.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalOrder
      v-if="showOrderModal"
      :order="order"
      @close="showOrderModal = false"
    />
  </div>
</template>

<script>
import ModalOrder from "@/components/Modals/Order";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalOrder,
  },

  props: {
    orders: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showOrderModal: false,
    };
  },

  computed: {
    ...mapState({
      order: (state) => state.orders.order,
    }),
  },

  methods: {
    ...mapActions("orders", ["getOrder"]),

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    getPrice(products) {
      let price = 0;

      products?.length &&
        products.forEach((product) => {
          price += +product.price;
        });

      return price;
    },

    getQuantity(products) {
      let quantity = 0;

      products?.length &&
        products.forEach((product) => {
          quantity += +product.quantity;
        });

      return quantity;
    },

    async openModal(order_id) {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.getOrder(order_id);
      if (success) {
        this.showOrderModal = true;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}
</style>
